export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  UseChevronForItemOptions: 'specs.stores.UseChevronForItemOptions',
  MakeCartItemNameLink: 'specs.stores.MakeCartItemNameLink',
  CartItemMoreOptionsFont: 'specs.stores.CartItemMoreOptionsFont',
  SendTrackEventWhenCheckoutLoad: 'specs.stores.SendTrackEventWhenCheckoutLoad',
  ErrorMessage: 'specs.stores.AddErrorMessageInCouponWhenTheInputIsEmpty',
  AddSidePaddingToCartSettingsForDesktop: 'specs.stores.AddSidePaddingSettingsOnCartPageForDesktop',
  UsePageUrlOnItemThumbnail: 'specs.stores.UsePageUrlOnItemThumbnail',
  ShouldShowIconSizeSetting: 'specs.stores.ShouldShowIconSizeSetting',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  FixSSRInCart: 'specs.stores.FixSSRInCart',
  NavigateToCartWhenDontShowSideCartOnMobile: 'specs.stores.NavigateToCartWhenDontShowSideCartOnMobile',
  ExpressCheckoutInSideCart: 'specs.stores.ExpressCheckoutInSideCart',
  FixCartAndSideCartNotSynced: 'specs.stores.FixCartAndSideCartNotSynced',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  FixSelectedThumbnailBug: 'specs.stores.FixSelectedThumbnailBug',
} as const;
